import { Dispatch, SetStateAction } from "react";
import { useContactModalStore } from "../ContactForm";
import SectionHeading from "./SectionHeading";

type Props = {
  setSearchModal?: Dispatch<SetStateAction<boolean>>;
};

export default function Contact({ setSearchModal }: Props) {
  const { setOpenContact } = useContactModalStore();
  const handleClickMessage = () => {
    setSearchModal && setSearchModal(false);
    setOpenContact(true);
  };

  return (
    <>
      <div className="grid mt-6 sm:mt-0 gap-4">
        <div className="pb-2 ">
          <SectionHeading title="Let's talk" icon="💬" />
          <div className="grid gap-x-4 gap-y-4 pt-4">
            <button
              onClick={handleClickMessage}
              className="opacity-90 text-left hover:opacity-100 transition hover:scale-105"
            >
              Message us
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
